import React, { useState } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import useAuth from "../../../hooks/useAuth";
import { BASE_URL } from "../../../utils/Element";
import { NotificationManager } from "react-notifications";
import SearchDropdown from "../../../components/Dropdowns/SearchDropdown";

const AddContractToClosedData = ({ formData, disable, error, onSetFormData, onSetError, handleSubmit, handleClose }) => {
  const [config] = useAuth();
  const [propertyOptions, setPropertyOptions] = useState([]);

  const handleChange = (value, name) => {
    onSetError({ ...error, [name]: "" });
    onSetFormData({ ...formData, [name]: value });
  };

  const fetchPropertySuggestions = (inputValue) => {
    axios
      .get(`${BASE_URL}/get-datalisting?list=properties&search=${inputValue}`, config)
      .then((res) => {
        const data = res.data.data.properties;
        const options = data.map((el) => ({ value: el.id, label: el?.property_name, desc: `${el.street_address}, ${el.city}, ${el.state}` }));
        setPropertyOptions(options);
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      });
  };

  const dateFormData = [
    { name: "purchase_agreement_draft_distributed", value: formData.purchase_agreement_draft_distributed, error: error?.purchase_agreement_draft_distributed, title: "Purchase Agreement Draft Distributed" },
    { name: "executed_purchase_agreeement", value: formData.executed_purchase_agreeement, error: error?.executed_purchase_agreeement, title: "Executed Purchase Agreeement" },
    { name: "transaction_timeline_distributed", value: formData.transaction_timeline_distributed, error: error?.transaction_timeline_distributed, title: "Transaction Timeline Distributed" },

    { name: "earnest_deposite_confirmed", value: formData.earnest_deposite_confirmed, error: error?.earnest_deposite_confirmed, title: "Earnest Deposite Confirmed" },
    { name: "due_diligence_delivery_receipt_confirmed", value: formData.due_diligence_delivery_receipt_confirmed, error: error?.due_diligence_delivery_receipt_confirmed, title: "Due Diligence Delivery Receipt Confirmed" },
    { name: "title", value: formData.title, error: error?.title, title: "Title" },

    { name: "address_title_issues", value: formData.address_title_issues, error: error?.address_title_issues, title: "Address Title Issues" },
    { name: "cordinate_inspection", value: formData.cordinate_inspection, error: error?.cordinate_inspection, title: "Cordinate Inspection" },
    { name: "survey_received", value: formData.survey_received, error: error?.survey_received, title: "Survey Received" },

    { name: "environmental_report_received", value: formData.environmental_report_received, error: error?.environmental_report_received, title: "Environmental Report Received" },
    { name: "appraisal_received", value: formData.appraisal_received, error: error?.appraisal_received, title: "Appraisal Received" },
    { name: "negotiate_repairs", value: formData.negotiate_repairs, error: error?.negotiate_repairs, title: "Negotiate Repairs" },

    { name: "due_diligence_expiration_date", value: formData.due_diligence_expiration_date, error: error?.due_diligence_expiration_date, title: "Due Diligence Expiration Date" },
    { name: "estoppel_snda_ordered", value: formData.estoppel_snda_ordered, error: error?.estoppel_snda_ordered, title: "Estoppel Snda Ordered" },
    { name: "financing_approval", value: formData.financing_approval, error: error?.financing_approval, title: "Financing Approval" },

    { name: "building_insurance_obtained", value: formData.building_insurance_obtained, error: error?.building_insurance_obtained, title: "Building Insurance Obtained" },
    { name: "estoppel_snda_received", value: formData.estoppel_snda_received, error: error?.estoppel_snda_received, title: "Estoppel & Snda Received" },
    { name: "scheduled_closing", value: formData.scheduled_closing, error: error?.scheduled_closing, title: "Scheduled & Closing" },

    { name: "comission_invoice_wiring_instruction", value: formData.comission_invoice_wiring_instruction, error: error?.comission_invoice_wiring_instruction, title: "Commission Invoice & Wiring Instruction" },
    { name: "review_settlement_statement", value: formData.review_settlement_statement, error: error?.review_settlement_statement, title: "Review Settlement & Statement" },
    { name: "closed_date", value: formData.closed_date, error: error?.closed_date, title: "Closed" },
  ];

  return (
    <form className="contact-details light-bg-L h-screen overflow-y-auto hide-scrollbar" onSubmit={handleSubmit}>
      <div className="flex gap-2">
        <div className="w-[50%]">
          <label className="dark-H head-4 mb-4">Property</label>
          <div className="mt-1">
            <SearchDropdown
              isTop={false}
              placeholder="Search Property"
              text={formData?.property_name}
              options={propertyOptions}
              fetchSuggestions={fetchPropertySuggestions}
              onSetOptions={(value) => setPropertyOptions(value)}
              handleKeyDown={(e) => {
                if (e.key === "Backspace") {
                  onSetFormData({
                    ...formData,
                    property_name: "",
                    property_id: "",
                  });
                  setPropertyOptions([]);
                }
              }}
              handleSelect={(option) => {
                onSetError({ ...error, property_id: "" });
                onSetFormData({
                  ...formData,
                  property_name: option?.label,
                  property_id: option?.value,
                });
                setPropertyOptions([]);
              }}
            />
          </div>
          {error?.property_id && <span className="body-S red-D">{error?.property_id}</span>}
        </div>

        <div className="w-[50%]">
          <label className="dark-H head-4">Executed LOI</label>
          <DatePicker
            className="body-N"
            dateFormat="MM/dd/yyyy"
            placeholderText="MM/DD/YYYY"
            // maxDate={new Date()}
            selected={formData.executed_loi}
            onChange={(date) => {
              handleChange(date, "executed_loi");
            }}
          />
          {error?.executed_loi && <span className="body-S red-D">{error?.executed_loi}</span>}
        </div>
      </div>

      <div className="flex gap-3 flex-wrap mt-6">
        {dateFormData?.flatMap((el, idx) => (
          <div key={idx} className="w-[32.5%] mb-2">
            <label className="dark-H head-4">{el.title}</label>
            <DatePicker
              className="body-N"
              dateFormat="MM/dd/yyyy"
              placeholderText="MM/DD/YYYY"
              // maxDate={new Date()}
              selected={el.value}
              onChange={(date) => {
                handleChange(date, el.name);
              }}
            />
            {el.error && <span className="body-S red-D">{el.error}</span>}
          </div>
        ))}
      </div>

      <div className="mt-6">
        <button type="submit" disabled={disable} className="save-button light-L head-5 green-bg-H">
          Save
        </button>
        <button type="button" onClick={handleClose} className="green-H ml-5">
          Cancel
        </button>
      </div>
    </form>
  );
};

export default AddContractToClosedData;
