import React, { useEffect, useRef, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import Loader from "../Loader";
import useAuth from "../../hooks/useAuth";
import { BASE_URL } from "../../utils/Element";
import Edit from "../../assets/svgs/Pencil.svg";
import { FiPlus as Plus } from "react-icons/fi";
import { smartPlanOptions } from "../../utils/options";
import Delete from "../../assets/svgs/Recycle Bin.svg";
import { handleDropdownClose } from "../../utils/utils";
import SmartPlanFilter from "../FilterComponents/SmartPlanFilter";
import { initialSmartPlanFilterData } from "../../utils/initialData";
import DeleteConfirmationModal from "../ConfirmationModals/DeleteConfirmationModal";
import CreateSmartPlanModal from "../../pages/Smartplans/modals/CreateSmartPlanModal";

const SmartPlanTab = ({ from }) => {
  const { id } = useParams();
  const [config] = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState("");
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [selectedId, setSelectedId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [smartPlanData, setSmartPlanData] = useState([]);
  const [disableDelete, setDisableDelete] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [filterData, setFilterData] = useState(() => {
    const localFilterData = localStorage.getItem("filterData");
    return localFilterData ? JSON.parse(localFilterData) : initialSmartPlanFilterData;
  });

  const queryParams = new URLSearchParams(location.search);
  const [search, setSearch] = useState(queryParams.get('search') ?? '');
  const [tabSearch, setTabSearch] = useState();

  useEffect(() => {
    const handleClose = () => {
      setIsOpen("");
    };
    handleDropdownClose(dropdownRef, handleClose);
  }, []);

  const buildQueryParams = (filters) => {
    let params = from === "contact" ? `page=${pageNumber}&per_page=${itemPerPage}&contact_id=${id}` : from === "company" ? `page=${pageNumber}&per_page=${itemPerPage}&company_id=${id}` : `page=${pageNumber}&per_page=${itemPerPage}`;

    const { 
      duration, 
      touches, 
      priority, 
      contacts, 
      task_type, 
      repeat_number, 
      link_other_smartplan 
    } = filters || {};

    if (duration !== "") {
      params += `&duration=${encodeURIComponent(duration)}`;
    }
    if (touches !== "") {
      params += `&touches=${encodeURIComponent(touches)}`;
    }
    if (task_type !== "") {
      params += `&task_type=${encodeURIComponent(task_type)}`;
    }
    if (repeat_number !== "no") {
      params += `&repeat_number=${encodeURIComponent(repeat_number)}`;
    }
  
    if (link_other_smartplan && 
    (Array.isArray(link_other_smartplan) && link_other_smartplan.length > 0 || 
    typeof link_other_smartplan === 'string' && link_other_smartplan.length > 0)) {
    params += `&link_other_smartplan=${encodeURIComponent(Array.isArray(link_other_smartplan) ? link_other_smartplan.join(",") : link_other_smartplan)}`;
    }

    if (priority && (Array.isArray(priority) && priority.length > 0 || typeof priority === 'string' && priority.length > 0)) {
      params += `&priority=${encodeURIComponent(Array.isArray(priority) ? priority.join(",") : priority)}`;
    }

    if (contacts?.length > 0) {
      params += `&contact_id=${encodeURIComponent(contacts.map((el) => el?.id))}`;
    }

    if (tabSearch) {
      params += `&search=${tabSearch}`;
    }

    return params;
  };

  const fetchSmartPlans = (filters) => {
    const queryParams = buildQueryParams(filters);
    axios
      .get(`${BASE_URL}/smartlisting?${queryParams}`, config)
      .then((res) => {
        const value = res?.data?.smartplans;
        setSmartPlanData(value?.data || []);
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    fetchSmartPlans(filterData);
  }, [pageNumber, itemPerPage , tabSearch]);

  const onSuccess = (filters) => {
    setLoading(true);
    if (filters) {
      fetchSmartPlans(filters);
    } else {
      fetchSmartPlans(filterData);
    }
  };

  const handleDelete = () => {
    setDisableDelete(true);
    axios
      .delete(`${BASE_URL}/delete-smartplan-contact/${id}/${selectedId}`, config)
      .then((res) => {
        if (res?.data?.message) {
          NotificationManager.success(res?.data?.message);
        }
        setSelectedId("");
        setShowDeleteModal(false);
        onSuccess();
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      })
      .finally(() => setDisableDelete(false));
  };

  
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams) {
      queryParams.set('search', search);
    } else {
      queryParams.delete('search'); 
    }
    navigate({ search: queryParams.toString() }, { replace: true });
  }, [search, navigate, location.search]);

  return (
    <div>
      <div className="flex justify-between items-start head-5 green-H">
        {/* <SmartPlanFilter
          filterData={filterData}
          onSetFilterData={(value) => {
            setFilterData(value);
          }}
          onCallApiAgain={(filters) => onSuccess(filters)}
        /> */}

            <div className="search-box contacts">
              <input
                type="text"
                className="body-S"
                placeholder="Search Here..."
                value={tabSearch}
                onChange={(e) => {
                  setTabSearch(e.target.value);
                }}
                style={{ border: "1px solid #D8D8D8", marginTop: "0px" }}
              />
            </div>

        <p className="head-5 green-H flex items-center gap-1" role="button" onClick={() => setShowModal(true)}>
          <Plus /> Add to TouchPlan
        </p>
      </div>

      {loading ? (
        <Loader />
      ) : smartPlanData?.length === 0 ? (
        <p className="body-N text-center mt-5">No TouchPlan Available</p>
      ) : (
        <div className="flex flex-wrap gap-4 mt-6">
          {smartPlanData?.flatMap((el, idx) => (
            <div ref={dropdownRef} className="custom-dropdown md:w-[49%]">
              <div key={idx} className="light-bg-L flex items-center justify-between gap-2 smart-plan-card">
                <div role="button" onClick={() => navigate(`/touch-plan/${el?.id}`)} className="flex flex-1 justify-between gap-3">
                  <div>
                    <p className="head-3 dark-H capitalize">{el?.name}</p>
                    <p className="body-S dark-M">
                      {el.touches_count} {el.touches_count > 1 ? "touches" : "touch"} | {el.step_count} {el.step_count > 1 ? "steps" : "step"}
                    </p>
                  </div>

                  <div>
                    <p className="body-N dark-H">{moment(el?.created_at).tz('America/New_York').format("MM/DD/YY")}</p>
                    <p className="head-6 dark-M">Date Added</p>
                  </div>

                  <div>
                    <p className="body-N dark-H">{moment(el?.updated_at).tz('America/New_York').format("MM/DD/YY")}</p>
                    <p className="head-6 dark-M">Date Last Executed</p>
                  </div>
                </div>

                <img
                  role="button"
                  onClick={() => {
                    navigate(`/touch-plan/${el?.id}`);
                  }}
                  src={Edit}
                  alt="icon"
                  className="sidebar-icons"
                />

                <img
                  role="button"
                  onClick={() => {
                    setSelectedId(el?.id);
                    setShowDeleteModal(true);
                  }}
                  src={Delete}
                  alt=""
                  className="sidebar-icons"
                />
              </div>

              {isOpen === idx && (
                <ul className="dropdown-list-container light-bg-L dark-M body-N p-2 shadow rounded-box" style={{ width: "100%" }}>
                  {el?.days?.[0]?.steps?.length === 0 ? (
                    <li>No Step Added</li>
                  ) : (
                    el?.days?.[0]?.steps?.flatMap((info, i) => (
                      <li key={i} className="ml-8" style={{ borderLeft: "1px dashed" }}>
                        <div className={`body-L dark-H flex gap-6 ${i !== 0 ? "pt-8" : ""} -ml-5`}>
                          <div role="button" className="green-bg-L rounded-full p-2 h-fit">
                            <img src={smartPlanOptions.find((e) => info?.category === e.value)?.icon} alt="" className="w-[20px]" />
                          </div>
                          <div>
                            <p className="capitalize">{smartPlanOptions.find((e) => info?.category === e.value)?.label}</p>
                            <p className="head-5 dark-M capitalize">{info.title}</p>
                          </div>
                        </div>
                      </li>
                    ))
                  )}
                </ul>
              )}
            </div>
          ))}
        </div>
      )}

      <DeleteConfirmationModal disable={disableDelete} showModal={showDeleteModal} onClose={() => setShowDeleteModal(false)} handleDelete={handleDelete} />

      <CreateSmartPlanModal id={id} from={from} showModal={showModal} onClose={(val) => {
        setShowModal(false);
        !!val && fetchSmartPlans();
      }} />
    </div>
  );
};

export default SmartPlanTab;
