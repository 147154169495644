import React, { useState } from "react";
import Dropdown from "react-dropdown";
import Plus from "../../assets/svgs/Plus 2.svg";
import Close from "../../assets/svgs/Close 2.svg";
import { emailOptions } from "../../utils/options";

const EmailField = ({ emails, onSetEmails }) => {
  const [showAddMsg, setShowAddMsg] = useState(false);

  // Handle adding a new email field
  const handleAdd = () => {
    const lastEmail = emails[emails.length - 1];

    // Check if the last email is incomplete
    if (emails.length > 0 && (lastEmail?.email_id === "" || lastEmail?.email_category === "")) {
      setShowAddMsg(true);
    } else {
      setShowAddMsg(false);
      onSetEmails([...emails, { email_category: "", email_id: "" }]);
    }
  };

  // Handle removing an email field or resetting the last field
  const handleRemove = (idx) => {
    if (emails.length === 1 && idx === 0) {
      // If it's the last field, reset its contents
      onSetEmails([{ email_category: "", email_id: "" }]);
    } else {
      const updatedEmails = emails.filter((_, i) => i !== idx);
      onSetEmails(updatedEmails);
    }
  };

  // Handle changing the values in dropdown or input field
  const handleChange = (value, name, idx) => {
    setShowAddMsg(false);
    const updatedEmails = emails.map((email, i) =>
      i === idx ? { ...email, [name]: value } : email
    );
    onSetEmails(updatedEmails);
  };

  return (
    <div className="mt-6">
      <label className="dark-H head-4 mb-2">
        Email <span className="body-S dark-M">(First is always primary)</span>
      </label>

      {emails.map((email, idx) => (
        <div key={idx} className="flex gap-2 items-center">
          <div className="flex flex-1 items-start">
            <div style={{ width: "30%" }}>
              <Dropdown
                className="phone-select body-N"
                options={emailOptions}
                placeholder="Select"
                value={emailOptions?.find((data) =>
                  email?.email_category?.includes(data?.value)
                )}
                onChange={(option) => {
                  handleChange(option.value, "email_category", idx);
                }}
              />
            </div>

            <input
              className="body-N"
              name="email_id"
              type="email"
              placeholder="Add Email"
              style={{ borderRadius: "0px 8px 8px 0px" }}
              value={email.email_id}
              onChange={(e) => handleChange(e.target.value, "email_id", idx)}
            />
          </div>

          {/* Buttons in the same line */}
          <div className="flex items-center gap-2">
            {/* Close button for resetting the last field or deleting others */}
            <img
              src={Close}
              alt="close"
              role="button"
              className="sidebar-icons"
              onClick={() => handleRemove(idx)}
            />

            {/* Plus button for adding a new field */}
            {idx === emails.length - 1 && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleAdd();
                }}
              >
                <img src={Plus} alt="Add" />
              </button>
            )}
          </div>
        </div>
      ))}

      {showAddMsg && (
        <p className="red-D body-S">Please fill all fields first</p>
      )}
    </div>
  );
};

export default EmailField;
