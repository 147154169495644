import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import Login from "../pages/Login/Login";
import Tasks from "../pages/Tasks/Tasks";
import Users from "../pages/Users/Users";
import Leads from "../pages/Leads/Leads";
import Buyers from "../pages/Buyers/Buyers";
import Offers from "../pages/Offers/Offers";
import Closed from "../pages/Closed/Closed";
import Tenants from "../pages/Settings/Tenants";
import Profile from "../pages/MyProfile/Profile";
import Contacts from "../pages/Contacts/Contacts";
import { ProtectedRoutes } from "./ProtectedRoutes";
import Dashboard from "../pages/Dashboard/Dashboard";
import NewPassword from "../pages/Login/NewPassword";
import Companies from "../pages/Companies/Companies";
import Proposals from "../pages/Proposals/Proposals";
import ContactTag from "../pages/Settings/ContactTag";
import Smartplan from "../pages/Smartplans/Smartplan";
import Exclusives from "../pages/Exclusive/Exclusives";
import LeadSources from "../pages/Settings/LeadSources";
import Properties from "../pages/Properties/Properties";
import AddProperty from "../pages/Properties/AddProperty";
import BuyersDetails from "../pages/Buyers/BuyersDetails";
import ConnectedApp from "../pages/Settings/ConnectedApp";
import PropertyTags from "../pages/Settings/PropertyTags";
import BuyerArchive from "../pages/Settings/BuyerArchive";
import ForgotPassword from "../pages/Login/ForgotPassword";
import EditProperty from "../pages/Properties/EditProperty";
import PropertyTypes from "../pages/Settings/PropertyTypes";
import OtpVerification from "../pages/Login/OtpVerification";
import PageNotFound from "../pages/PageNotFound/PageNotFound";
import ContactDetails from "../pages/Contacts/ContactDetails";
import ContactArchive from "../pages/Settings/ContactArchive";
import CompanyArchive from "../pages/Settings/CompanyArchive";
import CompanyDetails from "../pages/Companies/CompanyDetails";
import PropertyArchive from "../pages/Settings/PropertyArchive";
import UserProfileDetail from "../pages/Users/UserProfileDetail";
import UnderContract from "../pages/UnderContract/UnderContract";
import CreateSmartPlan from "../pages/Smartplans/CreateSmartPlan";
import PropertyDetails from "../pages/Properties/PropertyDetails";
import DesignatedAgents from "../pages/Settings/DesignatedAgents";
import SuperAdminProfile from "../pages/MyProfile/SuperAdminProfile";
import PasswordChangeSuccess from "../pages/Login/PasswordChangeSuccess";
import ContractToClosed from "../pages/ContractToClosed/ContractToClosed";
import AddContractToClosed from "../pages/ContractToClosed/AddContractToClosed";
import EditContractToClosed from "../pages/ContractToClosed/EditContractToClosed";
import FilterData from "../components/FilterData.jsx";
// import Homepage from "../pages/Home/Homepage";
// import Term_n_conditions from "../pages/Home/Components/Term_n_conditions";
// import PrivacyPolicy from "../pages/Home/Components/PrivacyPolicy";


const AppRoutes = () => {
  const userType = useSelector((state) => state.userType);

  return (
    <BrowserRouter>
      <Routes>

        <Route path="/login" index element={<Login />} />
        <Route path="/forgot-password" index element={<ForgotPassword />} />
        <Route path="/otp-verify" index element={<OtpVerification />} />
        <Route path="/new-password" index element={<NewPassword />} />
        <Route
          path="/password-success"
          index
          element={<PasswordChangeSuccess />}
        />
        <Route path="*" element={<PageNotFound />} />

        {/* Protected Routes  */}
        <Route element={<ProtectedRoutes />}>
          {(userType === 1 || userType === 2) && (
            <Route path="/users" index element={<Users />} />
          )}
          <Route path="/" index element={<Dashboard />} />
          <Route path="/user/:id" index element={<UserProfileDetail />} />
          <Route path="/contacts" index element={<Contacts />} />
          <Route path="/contact/:id" index element={<ContactDetails />} />
          <Route path="/companies" index element={<Companies />} />
          <Route path="/company/:id" index element={<CompanyDetails />} />
          <Route path="/properties" index element={<Properties />} />
          <Route path="/property/:id" index element={<PropertyDetails />} />
          <Route path="/add-property" index element={<AddProperty />} />
          <Route path="/edit-property/:id" index element={<EditProperty />} />
          <Route path="/buyers" index element={<Buyers />} />
          <Route path="/buyer/:id" index element={<BuyersDetails />} />
          <Route path="/tasks" index element={<Tasks />} />
          <Route path="/touch-plans" index element={<Smartplan />} />
          <Route path="/touch-plan/:id" index element={<CreateSmartPlan />} />
          <Route path="/leads" index element={<Leads />} />
          <Route path="/proposals" index element={<Proposals />} />
          <Route path="/offers" index element={<Offers />} />
          <Route path="/exclusives" index element={<Exclusives />} />
          <Route path="/under-contract" index element={<UnderContract />} />
          <Route path="/closed" index element={<Closed />} />
          <Route path="/contact-close" index element={<ContractToClosed />} />
          <Route path="FilterData/:id/:propertyId" index element={<FilterData/>}/>
          <Route
            path="/add-contact-close"
            index
            element={<AddContractToClosed />}
          />
          <Route
            path="/edit-contact-close/:id"
            index
            element={<EditContractToClosed />}
          />

          {/* Settings Routes */}
          <Route
            path="/settings/contact-archive"
            index
            element={<ContactArchive />}
          />
          <Route
            path="/settings/company-archive"
            index
            element={<CompanyArchive />}
          />
          <Route
            path="/settings/property-archive"
            index
            element={<PropertyArchive />}
          />
          <Route
            path="/settings/buyer-archive"
            index
            element={<BuyerArchive />}
          />
          <Route path="/settings/contact-tags" index element={<ContactTag />} />
          <Route
            path="/settings/lead-sources"
            index
            element={<LeadSources />}
          />
          <Route
            path="/settings/designated-agents"
            index
            element={<DesignatedAgents />}
          />
          <Route
            path="/settings/property-types"
            index
            element={<PropertyTypes />}
          />
          <Route
            path="/settings/property-tags"
            index
            element={<PropertyTags />}
          />
          <Route path="/settings/tenant" index element={<Tenants />} />
          <Route
            path="/settings/connected-app"
            index
            element={<ConnectedApp />}
          />
          <Route
            path="/settings/profile"
            index
            element={userType === 1 ? <SuperAdminProfile /> : <Profile />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
