import React, { useEffect, useRef, useState } from "react";
import { NotificationManager } from "react-notifications";
import DatePicker from "react-datepicker";
import moment from "moment";
import axios from "axios";
import Modal from "../../Modal/Modal";
import useAuth from "../../../hooks/useAuth";
import { BASE_URL } from "../../../utils/Element";
import { activityOptions } from "../../../utils/options";
import { handleDropdownClose } from "../../../utils/utils";
import { IoIosArrowDown as ArrowDown } from "react-icons/io";
import { useSelectedOptions } from "../../../context/selectedOptionsContext";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const today = moment.tz("America/New_York").format("YYYY-MM-DD");

const initialFormData = {
  date: today,
  description: "",
  interaction_type: "",
  contact_name: "",
};

const EditActivityModal = ({ showModal, onClose, activityId }) => {
  const [config] = useAuth();
  const dropdownRef = useRef(null);
  const [error, setError] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [description, setDescription] = useState("");
  const { setIsEditedactivity } = useSelectedOptions();

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (value, name) => {
    setError((prevError) => ({ ...prevError, [name]: "" }));
    setFormData((prevData) => ({ ...prevData, [name]: value })); // Set the correct value
  };

  const handleDescriptionChange = (html) => {
    setFormData((prevstate)=>({...prevstate,description:html}))
    setError((prevError) => ({ ...prevError, description: "" }));
  };

  useEffect(() => {
    const handleClose = () => {
      setIsOpen(false);
    };
    handleDropdownClose(dropdownRef, handleClose);
  }, []);

  useEffect(() => {
    const fetchData = () => {
      axios
        .get(`${BASE_URL}/activity-getbyid/${activityId}`, config)
        .then((res) => {
          const { data } = res.data;
          setFormData({ 
            date: data?.date, 
            interaction_type: data?.interaction_type, 
            description: data?.description 
          });
        })
        .catch((err) => {
          if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
            NotificationManager.error(err.response?.data?.message);
          }
        });
    };

    if (showModal && activityId) {
      fetchData();
    }
  }, [activityId, showModal]);

  const handleClose = () => {
    setIsEditedactivity(true);
    onClose();
    setError({});
    setFormData(initialFormData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const dataToSend = {
      interaction_type: formData?.interaction_type,
      date: formData.date,
      description: formData?.description,
    };

    axios
      .put(`${BASE_URL}/activity-edit/${activityId}`, dataToSend, config)
      .then((res) => {
        handleClose();
        if (res?.data?.message) {
          NotificationManager.success(res?.data?.message);
        }
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      });
  };

  return (
    <Modal title={"Edit Activity"} desc={"Update details for this activity here."} show={showModal} onClose={onClose}>
      <form className="py-3">
        <div className="flex gap-3 items-center">
          <div className="mt-5 md:mt-0 w-[50%]">
            <p className="head-4 dark-H">Interaction Type</p>
            <div ref={dropdownRef} className="custom-dropdown mt-2">
              <div
                role="button"
                className="select-header-input capitalize light-bg-L body-N dark-M flex justify-between items-center"
                onClick={() => setIsOpen(!isOpen)}
              >
                {formData.interaction_type === "" ? "Select" : formData.interaction_type} <ArrowDown />
              </div>
              {isOpen && (
                <div className="dropdown-list-container light-bg-L dark-M body-N shadow rounded-box w-52 ">
                  <ul className="dropdown-list">
                    {activityOptions.map((el, i) => (
                      <li
                        key={i}
                        role="button"
                        onClick={() => {
                          handleChange(el.value, "interaction_type");
                          setIsOpen(false);
                        }}
                        className={`${formData.interaction_type === el.value ? "active" : ""}`}
                      >
                        {el.label}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            {error?.interaction_type && <p className="body-S red-D">{error?.interaction_type}</p>}
          </div>

          <div className="mt-5 md:mt-0 w-[50%]">
            <p className="head-4 dark-H">Date</p>
            <div className="mt-1">
              <DatePicker
                className="body-N"
                dateFormat="MM/dd/yyyy"
                placeholderText="MM/DD/YYYY"
                selected={formData.date ? new Date(formData.date) : null}
                onChange={(date) => {
                  handleChange(moment.tz(date, "America/New_York").format("YYYY-MM-DD"), "date");
                }}
              />
            </div>
            {error?.date && <p className="body-S red-D">{error?.date}</p>}
          </div>
        </div>

        <div className="flex-1 mt-4">
          <p className="head-4 dark-H">
            Description <span className="body-S dar-M">(optional)</span>
          </p>
          {/* <textarea
            rows={4}
            placeholder="Enter description here..."
            className="mt-2 w-full body-N"
            value={formData?.description}
            onChange={(e) => {
              handleChange(e.target.value, "description");
            }}
          /> */}
            <ReactQuill 
            value={formData?.description}
            onChange={handleDescriptionChange}  
            rows={4}
            placeholder="Enter description here..."  
            className="mt-2 w-full body-N" />
          {error?.description && <p className="body-S red-D">{error?.description}</p>}
        </div>

        <div className="mt-6">
          <button type="submit" className="save-button light-L head-5 w-20 green-bg-H" onClick={handleSubmit}>
            Edit
          </button>
          <button type="button" onClick={onClose} className="green-H ml-5">
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default EditActivityModal;
