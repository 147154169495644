import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
import Loader from "../../components/Loader";
import Plus from "../../assets/svgs/Plus.svg";
import { BASE_URL } from "../../utils/Element";
import BaseLayout from "../../layouts/BaseLayout";
import ActionsMenu from "../../components/ActionsMenu";
import swap from "../../assets/svgs/swap-vertical.svg";
import sortAsce from "../../assets/svgs/sort-ascending.svg";
import sortDesc from "../../assets/svgs/sort-descending.svg";
import ContactPagination from "../../components/Pagination/ContactPagination";
import ConfirmationModal from "../../components/ConfirmationModals/ConfirmationModal";
import DeleteConfirmationModal from "../../components/ConfirmationModals/DeleteConfirmationModal";

const ContractToClosed = () => {
  const [config] = useAuth();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedId, setSelectedId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortDirection, setSortDirection] = useState("asc");
  const [disableDelete, setDisableDelete] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const isSidebarCollapsed = useSelector((state) => state.isSidebarCollapsed);
  const [paginationData, setPaginationData] = useState({ totalItems: 0, from: 0, to: 0, totalPages: 0 });

  const fetchData = () => {
    axios
      .get(`${BASE_URL}/contract-to-close-listing?page=${currentPage}&per_page=${itemPerPage}&search=${search}&sort_direction=${sortDirection}&sort_by=${sortBy}`, config)
      .then((res) => {
        const value = res?.data?.data?.contracts_to_close;
        setListData(value?.data || []);
        setPaginationData({
          totalItems: value?.total ?? 0,
          from: value?.from ?? 0,
          to: value?.to ?? 0,
          totalPages: value?.last_page ?? 0,
        });
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, [sortDirection, sortBy]);

  useEffect(() => {
    onSuccess();
  }, [search, currentPage, itemPerPage]);

  const onSuccess = () => {
    setLoading(true);
    fetchData();
  };

  const handleNext = () => {
    if (currentPage !== paginationData.totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleDelete = () => {
    setDisableDelete(true);
    axios
      .delete(`${BASE_URL}/contract-to-close/${selectedId}`, config)
      .then((res) => {
        setSelectedId("");
        onSuccess();
        setShowDeleteModal(false);
        if (res?.data?.message) {
          NotificationManager.success(res?.data?.message);
        }
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      })
      .finally(() => setDisableDelete(false));
  };

  const handleConfirm = () => {
    axios
      .put(`${BASE_URL}/mark-closed/${selectedId}`, { closed: true }, config)
      .then((res) => {
        setSelectedId("");
        onSuccess();
        setShowConfirmModal(false);
        if (res?.data?.message) {
          NotificationManager.success(res?.data?.message);
        }
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      });
  };

  return (
    <BaseLayout>
      <div className="contact-header">
        <p className="head-1 dark-H">Contract to Closed</p>
        <div className="md:flex block gap-6 items-center">
          <div className="flex gap-6 justify-between items-center">
            <div className="search-box contacts">
              <input
                type="text"
                className="body-S"
                placeholder="Search name, address, city, state..."
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                style={{ border: "1px solid #D8D8D8", marginTop: "0px" }}
              />
            </div>
            <button className="add-contact-button green-bg-H light-L body-S" onClick={() => navigate("/add-contact-close")}>
              <img className="mr-2" src={Plus} alt="plus" /> Add
            </button>
          </div>
        </div>
      </div>

      <div className="light-bg-L pb-3 table-container">
        <div className={`${isSidebarCollapsed ? "collapsed-width" : "width"} table-info light-bg-L`}>
          {loading ? (
            <Loader />
          ) : listData?.length === 0 ? (
            <p className="body-N text-center mt-6 p-10">No Data Available</p>
          ) : (
            <table className="contact-table light-bg-L">
              <thead>
                <tr className="uppercase body-N dark-M">
                  <th className="green-H ">
                    <div className="table-header">
                      Property
                      <img
                        role="button"
                        src={sortBy !== "property_name" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("property_name");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      Executed Loi
                      <img
                        role="button"
                        src={sortBy !== "executed_loi" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("executed_loi");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      Purchase Agreement
                      <br />
                      Draft Distributed
                      <img
                        role="button"
                        src={sortBy !== "purchase_agreement_draft_distributed" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("purchase_agreement_draft_distributed");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      Executed Purchase <br /> Agreeement
                      <img
                        role="button"
                        src={sortBy !== "executed_purchase_agreeement" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("executed_purchase_agreeement");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      Transaction Timeline <br /> Distributed
                      <img
                        role="button"
                        src={sortBy !== "transaction_timeline_distributed" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("transaction_timeline_distributed");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>

                  <th>
                    <div className="table-header">
                      Earnest Deposite <br /> Confirmed
                      <img
                        role="button"
                        src={sortBy !== "earnest_deposite_confirmed" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("earnest_deposite_confirmed");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>

                  <th>
                    <div className="table-header">
                      Due Diligence Delivery <br /> Receipt Confirmed
                      <img
                        role="button"
                        src={sortBy !== "due_diligence_delivery_receipt_confirmed" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("due_diligence_delivery_receipt_confirmed");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>

                  <th>
                    <div className="table-header">
                      Title
                      <img
                        role="button"
                        src={sortBy !== "title" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("title");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>

                  <th>
                    <div className="table-header">
                      Address <br /> Title Issues
                      <img
                        role="button"
                        src={sortBy !== "address_title_issues" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("address_title_issues");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>

                  <th>
                    <div className="table-header">
                      Cordinate <br /> Inspection
                      <img
                        role="button"
                        src={sortBy !== "cordinate_inspection" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("cordinate_inspection");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      Survey <br /> Received
                      <img
                        role="button"
                        src={sortBy !== "survey_received" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("survey_received");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      Environmental <br /> Report Received
                      <img
                        role="button"
                        src={sortBy !== "environmental_report_received" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("environmental_report_received");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      Appraisal <br /> Received
                      <img
                        role="button"
                        src={sortBy !== "appraisal_received" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("appraisal_received");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      Negotiate <br /> Repairs
                      <img
                        role="button"
                        src={sortBy !== "negotiate_repairs" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("negotiate_repairs");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      Due Diligence <br /> Expiration Date
                      <img
                        role="button"
                        src={sortBy !== "due_diligence_expiration_date" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("due_diligence_expiration_date");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      Estoppel <br /> Snda Ordered
                      <img
                        role="button"
                        src={sortBy !== "estoppel_snda_ordered" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("estoppel_snda_ordered");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      Financing <br /> Approval
                      <img
                        role="button"
                        src={sortBy !== "financing_approval" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("financing_approval");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      Building <br /> Insurance Obtained
                      <img
                        role="button"
                        src={sortBy !== "building_insurance_obtained" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("building_insurance_obtained");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      Estoppel & <br /> Snda Received
                      <img
                        role="button"
                        src={sortBy !== "estoppel_snda_received" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("estoppel_snda_received");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      Scheduled & <br /> Closing
                      <img
                        role="button"
                        src={sortBy !== "scheduled_closing" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("scheduled_closing");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      Commission Invoice & <br /> Wiring Instruction
                      <img
                        role="button"
                        src={sortBy !== "comission_invoice_wiring_instruction" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("comission_invoice_wiring_instruction");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      Review Settlement <br />& Statement
                      <img
                        role="button"
                        src={sortBy !== "review_settlement_statement" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("review_settlement_statement");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      Closed
                      <img
                        role="button"
                        src={sortBy !== "closed_date" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("closed_date");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">More</div>
                  </th>
                </tr>
              </thead>

              <tbody>
                {listData.map((el, idx) => (
                  <tr key={idx} className="dark-M body-N">
                   <td className="head-4 dark-H capitalize">
                   <a
                     href={`/property/${el?.property_id}`}
                     rel="noopener noreferrer"
                     onClick={(e) => {
                       e.preventDefault(); 
                       navigate(`/property/${el?.property_id}`); 
                     }}
                   >
                     {el.property?.property_name}
                   </a>
                 </td>

                    <td>{el?.executed_loi ? moment.tz(el?.executed_loi, 'America/New_York').format("MM/DD/YYYY") : ""}</td>
                    <td>{el?.purchase_agreement_draft_distributed ? moment.tz(el?.purchase_agreement_draft_distributed, 'America/New_York').format("MM/DD/YYYY") : ""}</td>
                    <td>{el?.executed_purchase_agreeement ? moment.tz(el?.executed_purchase_agreeement, 'America/New_York').format("MM/DD/YYYY") : ""}</td>
                    <td>{el?.transaction_timeline_distributed ? moment.tz(el?.transaction_timeline_distributed, 'America/New_York').format("MM/DD/YYYY") : ""}</td>
                    <td>{el?.earnest_deposite_confirmed ? moment.tz(el?.earnest_deposite_confirmed, 'America/New_York').format("MM/DD/YYYY") : ""}</td>
                    <td>{el?.due_diligence_delivery_receipt_confirmed ? moment.tz(el?.due_diligence_delivery_receipt_confirmed, 'America/New_York').format("MM/DD/YYYY") : ""}</td>
                    <td>{el?.title ? moment.tz(el?.title, 'America/New_York').format("MM/DD/YYYY") : ""}</td>
                    <td>{el?.address_title_issues ? moment.tz(el?.address_title_issues, 'America/New_York').format("MM/DD/YYYY") : ""}</td>
                    <td>{el?.cordinate_inspection ? moment.tz(el?.cordinate_inspection, 'America/New_York').format("MM/DD/YYYY") : ""}</td>
                    <td>{el?.survey_received ? moment.tz(el?.survey_received, 'America/New_York').format("MM/DD/YYYY") : ""}</td>
                    <td>{el?.environmental_report_received ? moment.tz(el?.environmental_report_received, 'America/New_York').format("MM/DD/YYYY") : ""}</td>
                    <td>{el?.appraisal_received ? moment.tz(el?.appraisal_received, 'America/New_York').format("MM/DD/YYYY") : ""}</td>
                    <td>{el?.negotiate_repairs ? moment.tz(el?.negotiate_repairs, 'America/New_York').format("MM/DD/YYYY") : ""}</td>
                    <td>{el?.due_diligence_expiration_date ? moment.tz(el?.due_diligence_expiration_date, 'America/New_York').format("MM/DD/YYYY") : ""}</td>
                    <td>{el?.estoppel_snda_ordered ? moment.tz(el?.estoppel_snda_ordered, 'America/New_York').format("MM/DD/YYYY") : ""}</td>
                    <td>{el?.financing_approval ? moment.tz(el?.financing_approval, 'America/New_York').format("MM/DD/YYYY") : ""}</td>
                    <td>{el?.building_insurance_obtained ? moment.tz(el?.building_insurance_obtained, 'America/New_York').format("MM/DD/YYYY") : ""}</td>
                    <td>{el?.estoppel_snda_received ? moment.tz(el?.estoppel_snda_received, 'America/New_York').format("MM/DD/YYYY") : ""}</td>
                    <td>{el?.scheduled_closing ? moment.tz(el?.scheduled_closing, 'America/New_York').format("MM/DD/YYYY") : ""}</td>
                    <td>{el?.comission_invoice_wiring_instruction ? moment.tz(el?.comission_invoice_wiring_instruction, 'America/New_York').format("MM/DD/YYYY") : ""}</td>
                    <td>{el?.review_settlement_statement ? moment.tz(el?.review_settlement_statement, 'America/New_York').format("MM/DD/YYYY") : ""}</td>
                    <td>{el?.closed_date ? moment.tz(el?.closed_date, 'America/New_York').format("MM/DD/YYYY") : ""}</td>

                    <td className="flex gap-3 items-center w-full justify-center">
                      <ActionsMenu
                        handleEdit={() => {
                          navigate(`/edit-contact-close/${el?.id}`);
                          setSelectedId(el?.id);
                        }}
                        handleDelete={() => {
                          setSelectedId(el?.id);
                          setShowDeleteModal(true);
                        }}
                        showOtherOption={true}
                        otherOptionTitle={"Close Contract"}
                        handleOtherOption={() => {
                          setShowConfirmModal(true);
                          setSelectedId(el?.id);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        <ContactPagination
          paginationData={paginationData}
          handleNext={handleNext}
          handlePrev={handlePrev}
          onSuccess={onSuccess}
          itemPerPage={itemPerPage}
          handleItemPerPageSelect={(value) => {
            setItemPerPage(value);
            setCurrentPage(1);
          }}
        />
      </div>

      <ConfirmationModal showModal={showConfirmModal} onClose={() => setShowConfirmModal(false)} handleConfirm={handleConfirm} />

      <DeleteConfirmationModal showModal={showDeleteModal} disable={disableDelete} onClose={() => setShowDeleteModal(false)} handleDelete={handleDelete} />
    </BaseLayout>
  );
};

export default ContractToClosed;
