import React from "react";
import moment from "moment";
import Modal from "./Modal/Modal";
import DatePicker from "react-datepicker";

const CustomDateFilterModal = ({ showModal, onClose, filterCustomDate, onSetFilterCustomDate, onSuccess }) => {
  const handleChange = (value, name) => {
    onSetFilterCustomDate({ ...filterCustomDate, [name]: value });
  };

  const handleFilter = () => {
    if (filterCustomDate?.startDate !== "" && filterCustomDate?.endDate !== "") {
      onSuccess();
      onClose();
    }
  };

  const handleClose = () => {
    onSetFilterCustomDate({ ...filterCustomDate, startDate: "", endDate: "" });
    onClose();
  };

  return (
    <Modal title={"Custom Filter"} desc={"Select Range"} show={showModal} onClose={handleClose}>
      <div className="flex gap-2 dark-H">
        <div>
          <label className="dark-H head-4">Start Date</label>
          <DatePicker
            className="body-N"
            dateFormat="MM/dd/yyyy"
            placeholderText="MM/DD/YYYY"
            selected={filterCustomDate.startDate}
            onChange={(date) => {
              const val = moment(date).format("YYYY-MM-DD");
              handleChange(val, "startDate");
            }}
          />
        </div>

        <div>
          <label className="dark-H head-4">End Date</label>
          <DatePicker
            className="body-N"
            dateFormat="MM/dd/yyyy"
            placeholderText="MM/DD/YYYY"
            selected={filterCustomDate.endDate}
            onChange={(date) => {
              const val = moment(date).format("YYYY-MM-DD");
              handleChange(val, "endDate");
            }}
          />
        </div>
      </div>

      <div className="mt-6">
        <button onClick={handleFilter} className="save-button light-L head-5 green-bg-H">
          Apply Filter
        </button>
        <button type="button" onClick={handleClose} className="green-H ml-5">
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default CustomDateFilterModal;
