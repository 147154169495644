import React from "react";

const ConnectAppBox = ({ image, title, desc, account, handleConnect, handleDisconnect }) => {
  return (
    <div className="flex items-center gap-6">
      <img src={image} alt="" style={{ height: "85px" }} />
      <div className="flex-1">
        <p className="head-4 dark-H">{title}</p>
        <p className="dark-M body-N mt-1">{desc}</p>
        {account !== "" && (
          <p className="tags green-H body-S mt-2" style={{ textTransform: "lowercase" }}>
            {account}
          </p>
        )}
      </div>
      <div>
        <p
          role="button"
          className="tags green-H body-S"
          onClick={() => {
            if (account !== "") {
              handleDisconnect();
            } else {
              handleConnect();
            }
          }}
        >
          {account !== "" ? "Disconnected Account" : "Connected Account"}
        </p>
      </div>
    </div>
  );
};

export default ConnectAppBox;
