import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
import Loader from "../../components/Loader";
import { BASE_URL } from "../../utils/Element";
import BaseLayout from "../../layouts/BaseLayout";
import AddContractToClosedData from "./components/AddContractToClosedData";

const initialData = {
  property_id: "",
  property_name: "",
  executed_loi: "",
  purchase_agreement_draft_distributed: "",
  executed_purchase_agreeement: "",
  transaction_timeline_distributed: "",
  earnest_deposite_confirmed: "",
  due_diligence_delivery_receipt_confirmed: "",
  title: "",
  address_title_issues: "",
  cordinate_inspection: "",
  survey_received: "",
  environmental_report_received: "",
  appraisal_received: "",
  negotiate_repairs: "",
  due_diligence_expiration_date: "",
  estoppel_snda_ordered: "",
  financing_approval: "",
  building_insurance_obtained: "",
  estoppel_snda_received: "",
  scheduled_closing: "",
  comission_invoice_wiring_instruction: "",
  review_settlement_statement: "",
  closed_date: "",
};

const EditContractToClosed = () => {
  const { id } = useParams();
  const [config] = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  const [formData, setFormData] = useState(initialData);

  const handleClose = () => {
    setFormData(initialData);
    setError();
    setDisable(false);
    navigate("/contact-close");
  };

  const fetchDetails = () => {
    setLoading(true);
    axios
      .get(`${BASE_URL}/contract-to-close/${id}`, config)
      .then((res) => {
        const data = res?.data?.data;
        setFormData({
          property_id: data?.property_id ?? "",
          property_name: data?.property?.property_name ?? "",
          executed_loi: data?.executed_loi ?? "",
          purchase_agreement_draft_distributed: data?.purchase_agreement_draft_distributed ?? "",
          executed_purchase_agreeement: data?.executed_purchase_agreeement ?? "",
          transaction_timeline_distributed: data?.transaction_timeline_distributed ?? "",
          earnest_deposite_confirmed: data?.earnest_deposite_confirmed ?? "",
          due_diligence_delivery_receipt_confirmed: data?.due_diligence_delivery_receipt_confirmed ?? "",
          title: data?.title ?? "",
          address_title_issues: data?.address_title_issues ?? "",
          cordinate_inspection: data?.cordinate_inspection ?? "",
          survey_received: data?.survey_received ?? "",
          environmental_report_received: data?.environmental_report_received ?? "",
          appraisal_received: data?.appraisal_received ?? "",
          negotiate_repairs: data?.negotiate_repairs ?? "",
          due_diligence_expiration_date: data?.due_diligence_expiration_date ?? "",
          estoppel_snda_ordered: data?.estoppel_snda_ordered ?? "",
          financing_approval: data?.financing_approval ?? "",
          building_insurance_obtained: data?.building_insurance_obtained ?? "",
          estoppel_snda_received: data?.estoppel_snda_received ?? "",
          scheduled_closing: data?.scheduled_closing ?? "",
          comission_invoice_wiring_instruction: data?.comission_invoice_wiring_instruction ?? "",
          review_settlement_statement: data?.review_settlement_statement ?? "",
          closed_date: data?.closed_date ?? "",
        });
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (id) {
      fetchDetails();
    }
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisable(true);
    const dataToSend = {
      property_id: formData?.property_id,
      executed_loi: formData?.executed_loi,
      purchase_agreement_draft_distributed: formData?.purchase_agreement_draft_distributed,
      executed_purchase_agreeement: formData?.executed_purchase_agreeement,
      transaction_timeline_distributed: formData?.transaction_timeline_distributed,
      earnest_deposite_confirmed: formData?.earnest_deposite_confirmed,
      due_diligence_delivery_receipt_confirmed: formData?.due_diligence_delivery_receipt_confirmed,
      title: formData?.title,
      address_title_issues: formData?.address_title_issues,
      cordinate_inspection: formData?.cordinate_inspection,
      survey_received: formData?.survey_received,
      environmental_report_received: formData?.environmental_report_received,
      appraisal_received: formData?.appraisal_received,
      negotiate_repairs: formData?.negotiate_repairs,
      due_diligence_expiration_date: formData?.due_diligence_expiration_date,
      estoppel_snda_ordered: formData?.estoppel_snda_ordered,
      financing_approval: formData?.financing_approval,
      building_insurance_obtained: formData?.building_insurance_obtained,
      estoppel_snda_received: formData?.estoppel_snda_received,
      scheduled_closing: formData?.scheduled_closing,
      comission_invoice_wiring_instruction: formData?.comission_invoice_wiring_instruction,
      review_settlement_statement: formData?.review_settlement_statement,
      closed_date: formData?.closed_date,
    };

    axios
      .put(`${BASE_URL}/contract-to-close/${id}`, dataToSend, config)
      .then((res) => {
        handleClose();
      })
      .catch((err) => {
        setDisable(false);
        setError(err.response?.data?.errors);
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      });
  };

  return (
    <BaseLayout>
      <div className="contact-header">
        <p className="head-1 dark-H md:px-0 px-3">Edit Contract To Closed</p>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <AddContractToClosedData
          formData={formData}
          error={error}
          onSetFormData={(value) => {
            setFormData(value);
          }}
          onSetError={(value) => setError(value)}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
          disable={disable}
        />
      )}
    </BaseLayout>
  );
};

export default EditContractToClosed;
