// import { Modal } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import useAuth from "../../../hooks/useAuth";
import { BASE_URL } from "../../../utils/Element";
import Modal from "../../../components/Modal/Modal";
import { useParams } from "react-router-dom";
import AddPropertyModalData from "./AddPropertyModalData";

const initialPropertyData = {
  propertyName: "",
  storeNumber: "",
  propertyType: "",
  streetAddress: "",
  city: "",
  state: "",
  zipcode: "",
  googleMapsLink: "",
  rprLink: "",
  comment: "",
  leaseType: null,
  leaseCommencementDate: "",
  leaseExpirationDate: "",
  annualRent: "",
  askingCapRate: "",
  askingPrice: "",
  buildingSize: "",
  landSize: "",
  owner_name: "",
  owner_contact_id: "",
  owner_company_id:"",
  ownerType: "",
  propertyTag: [],
  lastUpdateDate: '',
  property_id:'',
};


const AddPropertyModal = ({ showModal, onClose, onCallApi,modalData  }) => {
  const { id: paramId } = useParams();
  const [config] = useAuth();
  const [error, setError] = useState();
  const [id, setId] = useState(paramId || null); 
  const [propertyInfo, setPropertyInfo] = useState(initialPropertyData);
  console.log('modalData',modalData);
  const handleClose = () => {
    onClose();
    setError();
  };

  const clearError = (name) => {
    setError({ ...error, [name]: "" });
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    
    const payload = {  
      property_id: propertyInfo.property_id,
      property_name: propertyInfo.propertyName,
      store: propertyInfo.storeNumber,
      property_type: propertyInfo.propertyType,
      street_address: propertyInfo.streetAddress,
      city: propertyInfo.city,
      comment: propertyInfo.comment,
      state: propertyInfo.state,
      zipcode: propertyInfo.zipcode,
      google_map_link: propertyInfo.googleMapsLink,
      rpr_link: propertyInfo.rprLink,
      lease_type: propertyInfo.leaseType,
      lease_commencement_date: propertyInfo.leaseCommencementDate,
      lease_expiration_date: propertyInfo.leaseExpirationDate,
      anual_rent: propertyInfo.annualRent,
      asking_cap_rate: propertyInfo.askingCapRate,
      asking_price: propertyInfo.askingPrice,
      building_size: propertyInfo.buildingSize,
      land_size: propertyInfo.landSize,
      propertytag_id: propertyInfo?.propertyTag,
      last_updated_date: propertyInfo?.lastUpdateDate,
    };
    
    if (modalData?.ownerType === "contact") {
      payload.owner_contact_id = modalData?.owner_id; 
    } else if (modalData?.ownerType === "company") {
      payload.owner_company_id = modalData?.owner_id; 
    }
    
    axios.post(`${BASE_URL}/add-property`, payload, config)
      .then((res) => {
        if (res?.data?.message) {
          NotificationManager.success(res?.data?.message);
          onCallApi(); 
          handleClose(); 
        }
      })
      .catch((error) => {
        if (error.response?.data?.message) {
          NotificationManager.error(error.response?.data?.message);
        }
        setError(error?.response?.data?.errors);
      });
  };
  useEffect(() => {
    if (showModal) {
      setPropertyInfo(initialPropertyData); 
    }
  }, [showModal]);
  

  return (
    <div>
      <Modal title={`Add Property`} width="750px" desc={`Add the Property information.`} show={showModal} onClose={handleClose}>
        <AddPropertyModalData
          error={error}
          propertyInfo={propertyInfo}
          onSetFormData={(value) => {
            console.log('value', value)
            setPropertyInfo(value);
          }}
          handleSubmit={handleSubmit}
          onClose={handleClose}
          clearError={clearError}
        />
      </Modal>

    </div>
  );
};

export default AddPropertyModal;
