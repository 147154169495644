import React, { useState } from "react";
import Modal from "../../Modal/Modal";
import DatePicker from "react-datepicker";

const ResheduleTaskModal = ({ showModal, onClose, onDateChange, handleResheduleTask }) => {
  const [date, setDate] = useState("");

  return (
    <Modal title={"Reschedule Task"} desc={"Adjust task date to better fit your schedule"} show={showModal} onClose={onClose} className="linkContacts-modal" width="400px" zIndex="1050">
      <form>
        <div>
          <label className="dark-H head-4 mb-2">
            Change date<span className="red-D">*</span>
          </label>
          <DatePicker
            className="body-N"
            dateFormat="MM/dd/yyyy"
            placeholderText="MM/DD/YYYY"
            minDate={new Date()}
            selected={date}
            onChange={(date) => {
              setDate(date);
              onDateChange(date);
            }}
          />
        </div>

        <div className="mt-6">
          <button
            type="button"
            onClick={() => {
              handleResheduleTask();
              setDate("");
            }}
            className="save-button light-L head-5 green-bg-H"
          >
            Reschedule Task
          </button>

          <button type="button" onClick={onClose} className="green-H ml-5">
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ResheduleTaskModal;
